import React from 'react'
import { setDate } from '../utils/helpers'

// Geneva Station
const DEFAULT_STATION = {
  extraelems: [
    { vX: 120, name: 'soil_temperature', vN: 0 },
    { vX: 65, name: 'soil_tension', vN: 0 },
  ],
  srqual: true,
  affiliationUrl: 'https://nysipm.cornell.edu',
  elems: {
    srad: 132,
    lwet: 118,
    temp: 23,
    wdir: 130,
    wspd: 128,
    rhum: 24,
    prcp: 5,
  },
  activeStatus: true,
  affiliation: 'New York State IPM Program',
  lat: 42.8767,
  isIcao: false,
  id: 'gen newa',
  name: 'Geneva',
  lon: -77.031,
  state: 'NY',
  elev: 650,
}

const NEWA_STATES_BBOX = [
  [-111.0436, 30.1375],
  [-71.0842, 49.3845],
]

const DEFAULT_STATE = {
  counter: 0,
  isSearch: false,
  isInStateSearch: false,
  dateOfInterest: setDate(new Date()),
}

function reducer(state, action) {
  switch (action.type) {
    case 'setDateOfInterest':
      return {
        ...state,
        dateOfInterest: action.dateOfInterest,
      }
    case 'inStateSearch':
      return {
        ...state,
        counter: state.counter + 1,
        closestStations: action.closestStations,
        isSearch: true,
        isInStateSearch: true,
      }
    case 'outOfStateSearch':
      return {
        ...state,
        isSearch: true,
        isInStateSearch: false,
      }
    case 'resetGeoSearch':
      return {
        ...state,
        isSearch: false,
        isInStateSearch: false,
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const GlobalStateContext = React.createContext()
export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, DEFAULT_STATE)
  return (
    <GlobalStateContext.Provider
      value={{
        ...state,
        dispatch,
        NEWA_STATES_BBOX,
        DEFAULT_STATION,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateContext
