import { format, getDayOfYear } from "date-fns";
import statesAndProvinces from "../../static/states-and-provinces.json";

/////////////////////////////////////////////////////////////////////////
export function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, "");
}

/////////////////////////////////////////////////////////////////////////
export function decodeHtmlEntity(str) {
  return str.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec);
  });
}

/////////////////////////////////////////////////////////////////////////
export function removeEllipsis(str) {
  return str.replace("[&hellip;]", "...");
}

/////////////////////////////////////////////////////////////////////////
function compare(a, b) {
  let comparison = 0;
  if (a.name > b.name) {
    comparison = 1;
  } else if (a.name < b.name) {
    comparison = -1;
  }
  return comparison;
}

/////////////////////////////////////////////////////////////////////////
export function formatStationsToDisplayOnDropdownMenu(allStations) {
  const statesList = [...new Set(allStations.map((s) => s.state))].sort();

  return statesList.map((ps) => {
    const filtered = Object.values(allStations).filter((s) => s.state === ps);

    return {
      label: statesAndProvinces.find((s) => s.postalCode === ps).name,
      postalCode: ps,
      options: filtered.sort(compare),
    };
  });
}

////////////////////////////////////////////////////////////////////////////
export function isGeolocationInUSA(lat, lon) {
  const USA_BBOX = [-124.848974, 24.396308, -66.885444, 49.384358];
  return (
    lon >= USA_BBOX[0] &&
    lon <= USA_BBOX[2] &&
    lat >= USA_BBOX[1] &&
    lat <= USA_BBOX[3]
  );
}

// Degree to Radiant //////////////////////////////////////////////////////
function degTorad(deg) {
  return deg * (Math.PI / 180);
}

//////////////////////////////////////////////////////////////////////////
export function calculateBboxFromStations(stations) {
  const minLat = Math.min(...stations.map((s) => s.lat));
  const maxLat = Math.max(...stations.map((s) => s.lat));
  const minLon = Math.min(...stations.map((s) => s.lon));
  const maxLon = Math.max(...stations.map((s) => s.lon));
  const bbox = [
    [minLon, minLat],
    [maxLon, maxLat],
  ];
  return bbox;
}

// Straight-line distance in KM ///////////////////////////////////////////
export function calculateDistance(origLat, origLon, toLat, toLon) {
  const R = 6371; // km
  const dLat = degTorad(origLat - toLat);
  const dLon = degTorad(origLon - toLon);
  const toLatRad = degTorad(toLat);
  const origLatRad = degTorad(origLat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(toLatRad) *
      Math.cos(origLatRad);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

////////////////////////////////////////////////////////////////////////
export function orderStationsByDistanceFromUser(position, stations) {
  if (position !== null) {
    const stationsWithDistances = stations
      .filter((stn) => stn.activeStatus)
      .map((stn) => {
        const distance = calculateDistance(
          position.latitude,
          position.longitude,
          stn.lat,
          stn.lon
        );
        return { stn, distance: Math.round(distance) };
      });
    function compare(a, b) {
      return a.distance - b.distance;
    }
    return stationsWithDistances.sort(compare);
  } else {
    return [];
  }
}

// //////////////////////////////////////////////////////////////////////
export function determineStatePartner(station) {
  let statePartner = null;
  if (station) {
    const sp = statesAndProvinces.find((s) => s.postalCode === station.state);
    if (sp) {
      statePartner = sp;
    }
  }
  return statePartner;
}

////////////////////////////////////////////////////////////////////////
export function wdirInCompassUnit(wspd, wdir) {
  const compass = [
    "NNE",
    "NE",
    "ENE",
    "E",
    "ESE",
    "SE",
    "SSE",
    "S",
    "SSW",
    "SW",
    "WSW",
    "W",
    "WNW",
    "NW",
    "NNW",
    "N",
    "Vrb",
  ];
  const numbins = 16;
  const wdirint = 360.0 / numbins;
  const wdirinc = 0.5 * wdirint;
  let wdirbin = 0;

  // wspd is hourly wind speed (mph); wdir is hourly wind direction (degrees)
  if (wspd === 0 || wdir === 0) {
    // Calm winds determined by speed or direction
    wdirbin = 0;
  } else if (wdir === -1) {
    // ACIS code for variable winds
    wdirbin = numbins + 1;
  } else if (wdir >= 360 - wdirinc || wdir < 0 + wdirinc) {
    // Either side of North
    wdirbin = numbins;
  } else {
    for (let i = 1; i < numbins; i += 1) {
      const b = i * wdirint;
      if (wdir >= b - wdirinc && wdir < b + wdirinc) {
        wdirbin = i;
        break;
      }
    }
  }
  return compass[wdirbin];
}

/////////////////////////////////////////////////////////////
export function fromLatLonToState(lat, lon) {
  return statesAndProvinces.find((state) => {
    if (
      lat >= state.bbox[0][1] &&
      lat <= state.bbox[1][1] &&
      lon >= state.bbox[0][0] &&
      lon <= state.bbox[1][0]
    ) {
      return state;
    }
    return null;
  });
}

///////////////////////////////////////////////////////////////////////////
export function determineWeatherIconString(pop12Max, tskyMedian, currentHour) {
  // five options:  clear, mixed, cloudy, rain-mix, rain
  // DAY
  if (currentHour >= 4 && currentHour < 21) {
    if (pop12Max < 30) {
      if (tskyMedian < 30) {
        return "clear";
      } else if (tskyMedian < 80) {
        return "mixed";
      } else {
        return "cloudy";
      }
    } else {
      if (tskyMedian < 80) {
        return "rain-mix";
      } else {
        return "rain";
      }
    }
  }

  // NIGHT
  if (currentHour >= 21 || currentHour < 4) {
    if (pop12Max < 30) {
      if (tskyMedian < 30) {
        return "clear";
      } else if (tskyMedian < 80) {
        return "mixed";
      } else {
        return "cloudy";
      }
    } else {
      if (tskyMedian < 80) {
        return "rain-mix";
      } else {
        return "rain";
      }
    }
  }
}

// Convert Fahrenheit to Celcius ////////////////////////////////////////
export const fahrenheitToCelcius = (t) => ((t - 32) * 5) / 9;

// Convert Celcius to Fahrenheit ////////////////////////////////////////
export const celciusToFahrenheit = (t, missing) =>
  t === missing ? t : t * (9 / 5) + 32;

// Calculate MEDIAN /////////////////////////////////////////////////////
export function median(array) {
  array = array.sort();
  if (array.length % 2 === 0) {
    // array with even number elements
    return (array[array.length / 2] + array[array.length / 2 - 1]) / 2;
  } else {
    return array[(array.length - 1) / 2]; // array with odd number elements
  }
}

export function convertDateToStringForAPI(date, hour) {
  const dd = new Date(date);
  return [
    dd.getFullYear().toString().padStart(4, "0"),
    (dd.getMonth() + 1).toString().padStart(2, "0"),
    dd.getDate().toString().padStart(2, "0"),
    hour.toString().padStart(2, "0"),
  ].join("");
}

export function sdateEdate(dateOfInterest, seasonEnd) {
  const year = dateOfInterest.year;

  // sdate
  let sdate = `${year}010100`;

  // edate
  const eDate = new Date(`${year}-${seasonEnd}`);
  const emm = eDate.getMonth() + 1;
  const edate1 = eDate.getDate();
  const ehh = eDate.getHours();

  let edate = `${dateOfInterest.year}${emm < 10 ? `0${emm}` : emm}${
    edate1 < 10 ? `0${edate1}` : edate1
  }${ehh < 10 ? `0${ehh}` : ehh}`;

  if (dateOfInterest.isCurrentYear) {
    if (new Date().getTime() < eDate.getTime()) {
      edate = "now";
    }
  }

  return { sdate, edate };
}

export function setDate(dateOfInterestDateObj) {
  const dd = new Date(dateOfInterestDateObj);
  const year = dd.getFullYear();
  const lastHour = dd.getHours();
  dd.setMinutes(0);
  dd.setSeconds(0);
  dd.setMilliseconds(0);
  const ms = dd.getTime();
  const dayOfYear = getDayOfYear(dd);

  return {
    date: dd,
    year,
    isCurrentYear: new Date().getFullYear() === year,
    lastHour,
    ms,
    dayOfYear,
    sdate: `${year}010100`,
    server: format(dd, "yyyy-MM-dd"),
  };
}

// Determine GDD //////////////////////////////////////////////////////////
export function calculateCustomGdd(
  data,
  base,
  startDate,
  formula = "simpleAve"
) {
  const currentDayOfYear = getDayOfYear(new Date());
  const currentMonth = new Date().getMonth() + 1;
  let gdd = 0;
  const splittedBase = base.split("˚");

  // The base can be in Celsius or Fahrenheit ---------------
  let bbase;
  if (splittedBase[0] === "86/50") {
    bbase = 50;
  } else {
    bbase = parseFloat(splittedBase[0]);
  }
  // Index from which to start the accumulation
  let sDateIdx = 0; // default to January 1 index
  if (startDate === "March 1" && currentMonth >= 3) {
    sDateIdx = 60; // March 1 index
  } else if (startDate === "April 1" && currentMonth >= 4) {
    sDateIdx = 90; // April 1 index
  } else if (startDate === "May 1" && currentMonth >= 5) {
    sDateIdx = 120; // May 1 index
  }

  let results = [];
  if (data.length > sDateIdx) {
    data.slice(sDateIdx).forEach((d) => {
      let dd = 0;
      let minT = d.mint;
      let maxT = d.maxt;

      if (bbase === 4 || bbase === 14.3 || bbase === 39) {
        minT = fahrenheitToCelcius(d.mint);
        maxT = fahrenheitToCelcius(d.maxt);
      }

      if (splittedBase[0] === "86/50") {
        if (maxT > 86) maxT = 86;
        if (minT < 50) minT = 50;
      }

      // Chose formula to determine how to calculate degree day
      if (formula === "Baskerville Emin") {
        dd = baskervilleEmin(minT, maxT, bbase);
      } else {
        dd = simpleAve(minT, maxT, bbase);
      }

      gdd += dd;
      results.push({
        ...d,
        dd: Math.round(dd),
        gdd: Math.round(gdd),
      });
    });
  }

  return results.find((d) => d.dayOfYear === currentDayOfYear).gdd;
}

export function simpleAve(mint, maxt, base) {
  if (mint === "M" || maxt === "M") return "N/A";
  const avg = (mint + maxt) / 2;
  return avg - base > 0 ? avg - base : 0;
}

// This formula is used to calculate growing degree day //////////////////
export function baskervilleEmin(min, max, base) {
  let dd;
  if (min >= base) {
    // simple avg method
    const avg = (max + min) / 2;
    dd = avg - base;
  } else if (max <= base) {
    dd = 0;
  } else {
    const avg = (max + min) / 2;
    const amt = (max - min) / 2;
    const t1 = Math.sin((base - avg) / amt);
    dd =
      avg < 0
        ? 0
        : Math.abs(
            (amt * Math.cos(t1) - (base - avg) * (3.14 / 2 - t1)) / 3.14
          );
  }

  if (dd < 0) dd = 0;
  return dd;
}

export function ddHrIntegrated(tempArr, base) {
  const values = tempArr
    .filter((t) => t !== "M")
    .map((t) => (t > base ? t - base : 0));
  return values.reduce((a, b) => a + b) / values.length;
}

export function parsedStringDate(stringDate) {
  // string date format: 2020-12-23 or 12-23
  const d = stringDate.split("-");
  if (d.length === 3) {
    return new Date(Number(d[0]), Number(d[1] - 1), Number(d[2]));
  } else {
    const currentYear = new Date().getFullYear();
    return new Date(currentYear, Number(d[0] - 1), Number(d[1]));
  }
}

export function modelInSeason(dateObj, modelData) {
  const seasonStart = new Date(
    `${dateObj.year}-${modelData.seasonStart}`
  ).getTime();
  const seasonEnd = new Date(
    `${dateObj.year}-${modelData.seasonEnd}`
  ).getTime();

  return dateObj.ms >= seasonStart && dateObj.ms <= seasonEnd;
}

export function gddRangeToMsg(el, gdd) {
  if (gdd === "N/A") return;
  for (const [key, message] of Object.entries(el)) {
    const [lower, upper] = key.split("-");
    if (gdd >= +lower && gdd <= +upper) {
      return message;
    }
  }
}

export function dateRangeToMsg(el, dateOfInterest) {
  for (const [key, message] of Object.entries(el)) {
    const dateRange = key.split("|");
    const lowerDate = `${dateOfInterest.date.getFullYear()}-${
      dateRange[0]
    }T23:59:59`;

    const lowerDateDayOfYear = getDayOfYear(new Date(lowerDate));
    const upperDate = `${dateOfInterest.date.getFullYear()}-${
      dateRange[1]
    }T23:59:59`;
    const upperDateDayOfYear = getDayOfYear(new Date(upperDate));

    if (
      dateOfInterest.dayOfYear >= lowerDateDayOfYear &&
      dateOfInterest.dayOfYear <= upperDateDayOfYear
    ) {
      return message;
    }
  }
}

export function mixedKeysToMsg(keys, biofix, dateOfInterest) {
  const year = biofix.date.getFullYear();
  for (const [key, message] of Object.entries(keys)) {
    let lowerDateDayOfYear;
    let upperDateDayOfYear;
    const row = key.split("|");

    if (row[0] === "biofix") {
      lowerDateDayOfYear = biofix.dayOfYear;
    }
    if (row[0].endsWith("Days")) {
      const surplusDays = Number(row[0].split("+")[1].slice(0, 1));
      lowerDateDayOfYear = biofix.dayOfYear + surplusDays;
    }
    if (row[0].split("-").length === 2) {
      const lowerDate = `${year}-${row[0]}T23:59:59`;
      lowerDateDayOfYear = getDayOfYear(new Date(lowerDate));
    }

    if (row[1] === "biofix") {
      upperDateDayOfYear = biofix.dayOfYear;
    }
    if (row[1].endsWith("Days")) {
      const surplusDays = Number(row[1].split("+")[1].slice(0, 1));
      upperDateDayOfYear = biofix.dayOfYear + surplusDays;
    }
    if (row[1].split("-").length === 2) {
      const upperDate = `${year}-${row[1]}T23:59:59`;
      upperDateDayOfYear = getDayOfYear(new Date(upperDate));
    }

    if (
      dateOfInterest.dayOfYear >= lowerDateDayOfYear &&
      dateOfInterest.dayOfYear <= upperDateDayOfYear
    ) {
      // return message
      return { ...message, key };
    }
  }
}

export function msToNextHour() {
  return 3600000 - (new Date().getTime() % 3600000);
}

export function calculateGdd(dailyData, base, startingIdx, formula) {
  let gdd = 0;
  return dailyData.slice(startingIdx).map((day) => {
    let dd = "N/A";

    let mint;
    let maxt;
    if (base === 0) {
      mint = fahrenheitToCelcius(day.mint);
      maxt = fahrenheitToCelcius(day.maxt);
    } else {
      mint = day.mint;
      maxt = day.maxt;
    }

    if (mint !== "M" && maxt !== "M") {
      dd = formula(mint, maxt, base);
      gdd += dd;
    }
    return {
      ...day,
      dd: Math.round(dd),
      gdd: dd === "N/A" ? "N/A" : Math.round(gdd),
      dateDisplay: format(day.date, "yyyy-MM-dd"),
    };
  });
}

export const getStandardDeviation = (arr, usePopulation = false) => {
  const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
  return Math.sqrt(
    arr
      .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
      .reduce((acc, val) => acc + val, 0) /
      (arr.length - (usePopulation ? 0 : 1))
  );
};

export function formatDate(date) {
  return [
    date.getFullYear().toString().padStart(4, "0"),
    (date.getMonth() + 1).toString().padStart(2, "0"),
    date.getDate().toString().padStart(2, "0"),
  ].join("-");
}
