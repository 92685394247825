import config from "./auth_config.json"
import { GlobalStateProvider } from "./src/context/globalStateContext"
import { Auth0Provider } from "./src/context/react-auth0-spa"
import "./src/css/tailwindIndex.css"
import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import SnackbarProvider from "react-simple-snackbar"
import "typeface-eb-garamond"
import "typeface-nunito-sans"

const queryClient = new QueryClient()

// A function that routes the user to the right place
// after login
const onRedirectCallback = () => {
  window.history.pushState({}, document.title, window.location.pathname)
}

// Main Root Element ------------------------------------------------
export const wrapRootElement = ({ element }) => {
  // console.log("wrapRootElement")
  let redirect_uri = ""
  if (typeof window !== "undefined") {
    redirect_uri = `${window.location.origin}/user`
  }

  return (
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={redirect_uri}
      onRedirectCallback={onRedirectCallback}
      audience="https://newa.rcc-acis.workers.dev/v0"
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <GlobalStateProvider>{element}</GlobalStateProvider>
        </SnackbarProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Auth0Provider>
  )
}
