// src/react-auth0-spa.js
import createAuth0Client from '@auth0/auth0-spa-js'
import React, { useState, useEffect, useContext } from 'react'

const url = `https://newa.rcc-acis.workers.dev/v0/user`
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUserState] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const token = await auth0FromHook.getTokenSilently()
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const user = await response.json()

        // console.log(
        //   "useEffect: user: " + isAuthenticated + " " + JSON.stringify(user)
        // )
        setUserState(user)
      }
      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const token = await auth0Client.getTokenSilently()
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const user = await response.json()
    setUserState(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const token = await auth0Client.getTokenSilently()
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const user = await response.json()
    setLoading(false)
    setIsAuthenticated(true)
    setUserState(user)
  }

  const setUser = async (user) => {
    const token = await auth0Client.getTokenSilently()
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    })

    const nUser = await response.json()
    if (nUser.fail) {
      delete nUser.fail
      setUserState(nUser)
      throw Error('userinfo changed')
    } else {
      setUserState(nUser)
    }
  }

  const logout = async () => {
    let uri = ''
    // console.log(window)
    if (typeof window !== 'undefined') {
      uri = window.location.origin
    }
    return await auth0Client.logout({ returnTo: uri })
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        setUser,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: async (...p) =>
          await auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
